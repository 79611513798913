import { Box } from '@mui/system';
import React, { FC } from 'react';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import BecomeMemberImage from 'components/page/homePage/components/becomeMember/assets/BecomeMemberImage.png';
import SunImage from 'components/page/homePage/components/becomeMember/assets/Sun.svg';
import Image from 'components/image/Image';
import { Text } from 'modules/theme/components/text/Text';
import { Button } from 'modules/theme/components/button/Button';
import { useLoginModal } from 'components/loginModal/hooks/useLoginModal';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { getMyAccountClickEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { sunriseLeftDesktopKeyframes, spinCounterClockwiseKeyframes } from 'components/page/homePage/components/becomeMember/constants/Keyframes';

export const DesktopBecomeMember: FC = () => {
    const { loginModal, showLoginDialog } = useLoginModal();
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();
    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);

    return (
        <>
            <Box display={'flex'} justifyContent={'center'} gap={4}>
                <Box>
                    <Box position={'absolute'} sx={{
                        animation: `${sunriseLeftDesktopKeyframes} 0.5s , ${spinCounterClockwiseKeyframes} 1.25s infinite`,
                        animationDelay: '2s',
                        animationFillMode: 'forwards',
                        width: 50,
                        height: 50,
                        marginLeft: 5,
                        marginTop: 5,
                        zIndex: -1,
                    }}>
                        <SunImage width={50} height={50} />
                    </Box>
                    <Image src={BecomeMemberImage} alt={'becomeMemberImage'} style={{ objectFit: 'contain'}}/>
                </Box>

                <Box display={'flex'} flexDirection={'column'}>
                    <Text variant={'h4'} semibold>
                        Získajte kľúč k exkluzívnym benefitom
                    </Text>
                    <Box marginTop={1} marginBottom={2}>
                        <Text variant={'body2'}>
                        Vstúpte do sveta nehnuteľností a objavte naplno svoje možnosti.
                        </Text>
                        <Text variant={'body2'} semibold>
                        Dvere máte otvorené.
                        </Text>
                    </Box>
                    <Box width={160} marginBottom={5}>
                        <Button
                            secondary
                            fullWidth
                            text={(
                                <Text variant={'body2'} semibold color={(pallete) => pallete.labelPrimaryInverse}>
                                    Prihlásiť sa
                                </Text>
                            )}
                            onClick={() =>{
                                gtmSendEvent(
                                    getMyAccountClickEvent(isUserLoggedIn)
                                );
                                showLoginDialog();
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            {loginModal}
        </>
    );
};