'use client';

import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';

export const Title: FC = () => {

    return <Box
        mb={{ xs: 1, md: 2 }}
        maxWidth={{ xs: 300, sm: '100%' }}
        sx={{ textShadow: '0 0 4px rgba(0,0,0,0.4)' }}
    >
        <Text
            center
            semibold
            variant="display5"
            mobileVariant="h3"
            color={(palette) => palette.labelPrimaryInverse}
            component="h1"
        >
            Vybrať správne je&nbsp;kľúčové
        </Text>
    </Box>;
};