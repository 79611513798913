'use client';

import { FC } from 'react';
import { usePageViewEvent } from 'modules/gtmEvents/hooks/pageView/usePageViewEvent';
import { IPageProps } from 'modules/page/app/interfaces/IPageProps';

interface IGtmEvents {
    pageProps: IPageProps;
}

export const GtmEvents: FC<IGtmEvents> = ({
    pageProps,
}) => {
    usePageViewEvent(pageProps.searchParams);

    return null;
};