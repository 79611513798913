'use client';

import React, { FC, useState } from 'react';
import { IPopularRegion } from 'components/page/homePage/components/popularRegions/common/interfaces/IPopularRegion';
import { Grid2, Box, Theme } from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import { PopularRegionItem } from 'components/page/homePage/components/popularRegions/common/PopularRegionItem';

interface IProps {
    items: IPopularRegion[]
}

export const MobilePopularRegions: FC<IProps> = ({ items }) => {
    const [isTextShown, setIsTextShown] = useState<boolean>(false);

    return (
        <Box position={'relative'}>
            <Grid2 container spacing={1} maxHeight={isTextShown ? 'unset' : 275} overflow={'hidden'}>
                {items.map((item, index) => (
                    <Grid2 size={{xs: 12}} key={index}>
                        <PopularRegionItem item={item} />
                    </Grid2>
                ))}
            </Grid2>
            {(!isTextShown) && (
                <Box
                    position={'absolute'}
                    sx={{
                        content: '" "',
                        background: (theme: Theme) =>
                            `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, ${theme.palette.background.paper} 100%)`,
                        pointerEvents: 'none'
                    }}
                    left={0}
                    right={0}
                    bottom={0}
                    top={0}
                    zIndex={5}
                />
            )}
            <Box zIndex={10} position={'relative'} display={'flex'} justifyContent={'center'} mt={2} role={'button'} onClick={() => setIsTextShown(!isTextShown)} sx={{ cursor: 'pointer' }}>
                <Text variant={'body2'} noWrap underLine>
                    {isTextShown ? 'Zobraziť menej' : 'Ďalšie lokality'}
                </Text>
            </Box>
        </Box>
    );
};