import { FC } from 'react';
import Link from 'components/link/Link';
import { Box, Stack, Theme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { IModalItem } from 'components/page/homePage/interfaces/whatAreYouLookingFor/IModalItem';
import { getClickOnCategoryEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';

interface IItem {
    item: IModalItem;
    divider: boolean;
}

export const Item: FC<IItem> = ({ item, divider }) => {
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);

    return (
        <Link
            noUnderline
            href={item.link}
            onClick={() =>
                gtmSendEvent(
                    getClickOnCategoryEvent(isUserLoggedIn, item.parentSef, item.sef)
                )
            }
        >
            <Box
                borderBottom={(theme: Theme) =>
                    divider && `1px solid ${theme.palette.dividerTransparent}`
                }
                paddingTop={'12px'}
                paddingBottom={'12px'}
            >
                <Stack direction="row" gap={1} alignItems="center">
                    <Svg icon component={item.icon} width={24} />
                    <Text variant="body2">{item.label}</Text>
                </Stack>
            </Box>
        </Link>
    );
};
