import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';

export const AdsDivider: FC = () => {
    const { palette } = useTheme();

    return (
        <Box
            display="flex"
            gap={3}
            width="100%"
            alignItems="center"
            marginBottom={2}
        >
            <Box
                width="100%"
                height={1}
                border={`1px solid ${palette.dividerTransparent}`}
            />
            <Text
                variant="label2"
                textTransform="uppercase"
                semibold
                color={(palette) => palette.labelSecondary}
            >
                Reklama
            </Text>
            <Box
                width="100%"
                height={1}
                border={`1px solid ${palette.dividerTransparent}`}
            />
        </Box>
    );
};
