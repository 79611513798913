'use client';

import { FC } from 'react';
import { Box } from '@mui/system';
import {Text} from 'modules/theme/components/text/Text';

export const Title: FC = () => {

    return (
        <Box width={'100%'} display={'flex'} justifyContent={'center'} marginTop={3} marginBottom={3}>
            <Text variant={'h4'} mobileVariant={'h5'} component={'h3'} semibold>
                Populárne regióny na Slovensku
            </Text>
        </Box>
    );
};