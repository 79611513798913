import { Box } from '@mui/system';
import React, { FC } from 'react';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import BecomeMemberImage from 'components/page/homePage/components/becomeMember/assets/BecomeMemberImage.png';
import Image from 'components/image/Image';
import { Text } from 'modules/theme/components/text/Text';
import { Button } from 'modules/theme/components/button/Button';
import { useLoginModal } from 'components/loginModal/hooks/useLoginModal';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { getMyAccountClickEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { sunriseLeftMobileKeyframes, spinCounterClockwiseKeyframes } from 'components/page/homePage/components/becomeMember/constants/Keyframes';
import SunImage from 'components/page/homePage/components/becomeMember/assets/Sun.svg';

export const MobileBecomeMember: FC = () => {
    const { loginModal, showLoginDialog } = useLoginModal();
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();
    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);

    return (
        <>
            <Box display={'flex'} alignItems={'center'} flexDirection={'column'} marginBottom={2} textAlign={'center'}>
                <Text variant={'h4'} semibold>
                    Získajte kľúč<br />k exkluzívnym benefitom
                </Text>
            </Box>
            <Box display={'flex'} justifyContent={'center'} gap={2}>
                <Box>
                    <Box position={'absolute'} sx={{
                        animation: `${sunriseLeftMobileKeyframes} 0.5s, ${spinCounterClockwiseKeyframes} 1.25s infinite`,
                        animationDelay: '2s',
                        animationFillMode: 'forwards',
                        width: 35,
                        height: 35,
                        marginLeft: 3,
                        marginTop: 3,
                        zIndex: -1,
                    }}>
                        <SunImage width={35} height={35} />
                    </Box>
                    <Image
                        style={{
                            objectFit: 'contain',
                        }}
                        src={BecomeMemberImage}
                        alt={'becomeMemberImage'}
                        height={60}
                        width={80}
                    />
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                    <Text variant={'body2'}>
                        Vstúpte do sveta nehnuteľností<br />a objavte naplno svoje možnosti.
                    </Text>
                    <Text variant={'body2'} semibold>
                        Dvere máte otvorené.
                    </Text>
                    <Box width={160} marginTop={2} marginBottom={5}>
                        <Button
                            secondary
                            fullWidth
                            text={(
                                <Text variant={'body2'} semibold color={(pallete) => pallete.labelPrimaryInverse}>
                                    Prihlásiť sa
                                </Text>
                            )}
                            onClick={() => {
                                gtmSendEvent(
                                    getMyAccountClickEvent(isUserLoggedIn)
                                );
                                showLoginDialog();
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            {loginModal}
        </>
    );
};